import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import qs from "query-string"
import { navigate } from "gatsby"

import SEO from "../../components/seo"
import Spinner from "../../components/ui/LoadingSpinner"
import SwapCheckout from "../../components/swap-checkout"
import Medusa from "../../services/api"
import { MedusaCheckoutProvider } from "../../components/medusa-checkout-builder"
import CheckoutLayout from "../../components/checkout/checkout-layout"

const Container = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  justify-constent: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`

const Swaps = ({}) => {
  const [isLoading, setLoading] = useState(true)
  const [loadError, setLoadError] = useState()
  const [cartId, setCartId] = useState(null)

  useEffect(() => {
    const temp = qs.parse(window.location.search)
    if (!temp.sct) {
      navigate("/")
    }

    Medusa.swaps
      .retrieveByCartId(temp.sct)
      .then(() => {
        setCartId(temp.sct)
      })
      .catch((err) => {
        setLoadError(err)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <MedusaCheckoutProvider cartId={cartId}>
      <CheckoutLayout showNavbar>
        <SEO title={"Your Swap"} />
        <Container>{isLoading ? <Spinner /> : <SwapCheckout />}</Container>
      </CheckoutLayout>
    </MedusaCheckoutProvider>
  )
}

export default Swaps
